//import anime from 'animejs';
import checkDevice from './checkDevice';
import vhCheck from 'vh-check';
require('bootstrap');

let breakpoints = {
    desktop:        '1200',
    tablet:         '768',
    mobile:         '375',
    smallMobile:    '0'
}

$(document).ready(function() {
    //Kiểm tra chiều cao 100vh thực sự của màn hình thiết bị
    vhCheck();
    //Check device type and browser type
    checkDevice(breakpoints);
});
