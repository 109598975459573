import $ from 'jquery';
import is from 'is_js';
const checkDevice = (breakpoints) => {
    //Detect browser
    if(is.ie()) {
        $('html').addClass('ie')
    } else if(is.edge()) {
        $('html').addClass('edge')
    } else if(is.chrome()) {
        $('html').addClass('chrome')
    } else if(is.firefox()) {
        $('html').addClass('firefox')
    } else if(is.safari()) {
        $('html').addClass('safari')
    } else {
        $('html').addClass('other-browser')
    }
    //Detect if device can touch or not
    if(is.touchDevice()) {
        $('html').addClass('touch')
    } else {
        $('html').addClass('no-touch')
    }
    //Detect device type: iOS or Android
    if(is.ios()) {
        $('html').addClass('ios')
    } else if(is.android()) {
        $('html').addClass('android')
    } else if(is.desktop()) {
        $('html').addClass('desktop')
    } else {
        $('html').addClass('other-device')
    }
    //Check device width to set view-state
    let _checkViewPortSize = function() {
        $('html').removeClass('view-desktop view-tablet view-mobile view-small-mobile')
        if($(window).width() >= breakpoints['desktop']) {
            $('html').addClass('view-desktop')
        } else if($(window).width() >= breakpoints['tablet']) {
            $('html').addClass('view-tablet')
        } else if ($(window).width() >= breakpoints['mobile']) {
            $('html').addClass('view-mobile')
        } else {
            $('html').addClass('view-mobile view-small-mobile')
        }
    }

    _checkViewPortSize();

    //Resize window
    $(window).on('resize', function() {
        _checkViewPortSize();
    });
}

export default checkDevice;